//import "./assets/vendors/base/vendor.bundle.base.css";
import logo from "./../assets/images/logo-full.png";
import logoMini from "./../assets/images/logo-site-mini.png";
import avatarProfile from "./../assets/images/faces/face28.png";
import imgLive from "./../assets/images/dashboard/live.png";

//Link Page
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import CurrencyFormat from "react-currency-format";
import CurrencyInput from "react-currency-input-field";
//import { data, error } from "jquery";

const Dashboard = () => {
  const [date, setDate] = useState(new Date());
  const [currencyData, setCurrencyData] = useState({
    exchangeRates: {},
    amount: 1,
    fromCurrency: "KRW",
    toCurrency: "IDR",
    convertedAmount: null,
  });
  const currencyNames = {
    AED: "United Arab Emirates Dirham",
    AFN: "Afghan Afghani",
    ALL: "Albanian Lek",
    AMD: "Armenian Dram",
    ANG: "Netherlands Antillean Guilder",
    AOA: "Angolan Kwanza",
    ARS: "Argentine Peso",
    AUD: "Australian Dollar",
    AWG: "Aruban Florin",
    AZN: "Azerbaijani Manat",
    BAM: "Bosnia and Herzegovina Convertible Mark",
    BBD: "Barbadian Dollar",
    BDT: "Bangladeshi Taka",
    BGN: "Bulgarian Lev",
    BHD: "Bahraini Dinar",
    BIF: "Burundian Franc",
    BMD: "Bermudian Dollar",
    BND: "Brunei Dollar",
    BOB: "Bolivian Boliviano",
    BRL: "Brazilian Real",
    BSD: "Bahamian Dollar",
    BTN: "Bhutanese Ngultrum",
    BWP: "Botswana Pula",
    BYN: "Belarusian Ruble",
    BZD: "Belize Dollar",
    CAD: "Canadian Dollar",
    CDF: "Congolese Franc",
    CHF: "Swiss Franc",
    CLP: "Chilean Peso",
    CNY: "Chinese Yuan",
    COP: "Colombian Peso",
    CRC: "Costa Rican Colón",
    CUP: "Cuban Peso",
    CVE: "Cape Verdean Escudo",
    CZK: "Czech Koruna",
    DJF: "Djiboutian Franc",
    DKK: "Danish Krone",
    DOP: "Dominican Peso",
    DZD: "Algerian Dinar",
    EGP: "Egyptian Pound",
    ERN: "Eritrean Nakfa",
    ETB: "Ethiopian Birr",
    EUR: "Euro",
    FJD: "Fijian Dollar",
    FKP: "Falkland Islands Pound",
    FOK: "Faroese Króna",
    GBP: "British Pound Sterling",
    GEL: "Georgian Lari",
    GGP: "Guernsey Pound",
    GHS: "Ghanaian Cedi",
    GIP: "Gibraltar Pound",
    GMD: "Gambian Dalasi",
    GNF: "Guinean Franc",
    GTQ: "Guatemalan Quetzal",
    GYD: "Guyanese Dollar",
    HKD: "Hong Kong Dollar",
    HNL: "Honduran Lempira",
    HRK: "Croatian Kuna",
    HTG: "Haitian Gourde",
    HUF: "Hungarian Forint",
    IDR: "Indonesian Rupiah",
    ILS: "Israeli New Shekel",
    IMP: "Isle of Man Pound",
    INR: "Indian Rupee",
    IQD: "Iraqi Dinar",
    IRR: "Iranian Rial",
    ISK: "Icelandic Króna",
    JEP: "Jersey Pound",
    JMD: "Jamaican Dollar",
    JOD: "Jordanian Dinar",
    JPY: "Japanese Yen",
    KES: "Kenyan Shilling",
    KGS: "Kyrgyzstani Som",
    KHR: "Cambodian Riel",
    KID: "Kiribati Dollar",
    KMF: "Comorian Franc",
    KRW: "South Korean Won",
    KWD: "Kuwaiti Dinar",
    KYD: "Cayman Islands Dollar",
    KZT: "Kazakhstani Tenge",
    LAK: "Lao Kip",
    LBP: "Lebanese Pound",
    LKR: "Sri Lankan Rupee",
    LRD: "Liberian Dollar",
    LSL: "Lesotho Loti",
    LYD: "Libyan Dinar",
    MAD: "Moroccan Dirham",
    MDL: "Moldovan Leu",
    MGA: "Malagasy Ariary",
    MKD: "Macedonian Denar",
    MMK: "Burmese Kyat",
    MNT: "Mongolian Tögrög",
    MOP: "Macanese Pataca",
    MRU: "Mauritanian Ouguiya",
    MUR: "Mauritian Rupee",
    MVR: "Maldivian Rufiyaa",
    MWK: "Malawian Kwacha",
    MXN: "Mexican Peso",
    MYR: "Malaysian Ringgit",
    MZN: "Mozambican Metical",
    NAD: "Namibian Dollar",
    NGN: "Nigerian Naira",
    NIO: "Nicaraguan Córdoba",
    NOK: "Norwegian Krone",
    NPR: "Nepalese Rupee",
    NZD: "New Zealand Dollar",
    OMR: "Omani Rial",
    PAB: "Panamanian Balboa",
    PEN: "Peruvian Nuevo Sol",
    PGK: "Papua New Guinean Kina",
    PHP: "Philippine Peso",
    PKR: "Pakistani Rupee",
    PLN: "Polish Złoty",
    PYG: "Paraguayan Guarani",
    QAR: "Qatari Riyal",
    RON: "Romanian Leu",
    RSD: "Serbian Dinar",
    RUB: "Russian Ruble",
    RWF: "Rwandan Franc",
    SAR: "Saudi Riyal",
    SBD: "Solomon Islands Dollar",
    SCR: "Seychellois Rupee",
    SDG: "Sudanese Pound",
    SEK: "Swedish Krona",
    SGD: "Singapore Dollar",
    SHP: "Saint Helena Pound",
    SLE: "Sierra Leonean Leone",
    SLL: "Sierra Leonean Leone",
    SOS: "Somali Shilling",
    SRD: "Surinamese Dollar",
    SSP: "South Sudanese Pound",
    STN: "São Tomé and Príncipe Dobra",
    SYP: "Syrian Pound",
    SZL: "Swazi Lilangeni",
    THB: "Thai Baht",
    TJS: "Tajikistani Somoni",
    TMT: "Turkmenistani Manat",
    TND: "Tunisian Dinar",
    TOP: "Tongan Paanga",
    TRY: "Turkish Lira",
    TTD: "Trinidad and Tobago Dollar",
    TVD: "Tuvaluan Dollar",
    TWD: "New Taiwan Dollar",
    TZS: "Tanzanian Shilling",
    UAH: "Ukrainian Hryvnia",
    UGX: "Ugandan Shilling",
    USD: "United States Dollar",
    UYU: "Uruguayan Peso",
    UZS: "Uzbekistani Som",
    VES: "Venezuelan Bolívar",
    VND: "Vietnamese Đồng",
    VUV: "Vanuatu Vatu",
    WST: "Samoan Tala",
    XAF: "Central African CFA Franc",
    XCD: "Eastern Caribbean Dollar",
    XDR: "International Monetary Fund (IMF) Special Drawing Rights",
    XOF: "West African CFA Franc",
    XPF: "CFP Franc",
    YER: "Yemeni Rial",
    ZAR: "South African Rand",
    ZMW: "Zambian Kwacha",
    ZWL: "Zimbabwean Dollar",
  };

  useEffect(() => {
    const { fromCurrency } = currencyData;
    fetch(`https://api.exchangerate-api.com/v4/latest/${fromCurrency}`)
      .then((response) => response.json())
      .then((data) => {
        setCurrencyData((prevData) => ({
          ...prevData,
          exchangeRates: data.rates,
        }));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [currencyData.fromCurrency]);

  useEffect(() => {
    const { amount, exchangeRates, toCurrency } = currencyData;
    if (exchangeRates[toCurrency] !== undefined) {
      setCurrencyData((prevData) => ({
        ...prevData,
        convertedAmount: amount * exchangeRates[toCurrency],
      }));
    }
  }, [currencyData.amount, currencyData.exchangeRates, currencyData.toCurrency]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrencyData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    setInterval(() => setDate(new Date()), 30000);
  });

  return (
    <div className="container-scroller">
      <div className="horizontal-menu">
        <nav className="navbar top-navbar col-lg-col-12 col-12 p-0">
          <div className="container-fluid">
            <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
              <ul className="navbar-nav navbar-nav-left">
                <li className="nav-items ms-0 me-5 d-lg-flex d-none">
                  <a className="nav-link horizontal-nav-left-menu">
                    <i className="mdi-mdi-format-list-bulleted"></i>
                  </a>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link count-indicator dropdown-toggle d-flex align-items-center justify-content-center" id="notificationDropdown" href="#" data-bs-toggle="dropdown">
                    <i className="mdi mdi-bell mx-0"></i>
                    <span className="count bg-success">4</span>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="notificationDropdown">
                    <p className="mb-0 font-weight-normal float-left dropdown-header">Notifications</p>
                    <a className="dropdown-item preview-item">
                      <div className="preview-thumbnail">
                        <div className="preview-icon bg-success">
                          <i className="mdi mdi-information mx-0"></i>
                        </div>
                      </div>
                      <div className="preview-item-content">
                        <h6 className="preview-subject font-weight-normal">Application Error</h6>
                        <p className="font-weight-light small-text mb-0 text-muted">Just now</p>
                      </div>
                    </a>
                    <a className="dropdown-item preview-item">
                      <div className="preview-thumbnail">
                        <div className="preview-icon bg-warning">
                          <i className="mdi mdi-settings mx-0"></i>
                        </div>
                      </div>
                      <div className="preview-item-content">
                        <h6 className="preview-subject font-weight-normal">Settings</h6>
                        <p className="font-weight-light small-text mb-0 text-muted">Private message</p>
                      </div>
                    </a>
                    <a className="dropdown-item preview-item">
                      <div className="preview-thumbnail">
                        <div className="preview-icon bg-info">
                          <i className="mdi mdi-account-box mx-0"></i>
                        </div>
                      </div>
                      <div className="preview-item-content">
                        <h6 className="preview-subject font-weight-normal">New user registration</h6>
                        <p className="font-weight-light small-text mb-0 text-muted">2 days ago</p>
                      </div>
                    </a>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link count-indicator dropdown-toggle d-flex justify-content-center align-items-center" id="messageDropdown" href="#" data-bs-toggle="dropdown">
                    <i className="mdi mdi-email mx-0"></i>
                    <span className="count bg-primary">4</span>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="messageDropdown">
                    <p className="mb-0 font-weight-normal float-left dropdown-header">Messages</p>
                    <a className="dropdown-item preview-item">
                      <div className="preview-thumbnail">
                        <img src="images/faces/face4.jpg" alt="image" className="profile-pic" />
                      </div>
                      <div className="preview-item-content flex-grow">
                        <h6 className="preview-subject ellipsis font-weight-normal">David Grey</h6>
                        <p className="font-weight-light small-text text-muted mb-0">The meeting is cancelled</p>
                      </div>
                    </a>
                    <a className="dropdown-item preview-item">
                      <div className="preview-thumbnail">
                        <img src="images/faces/face2.jpg" alt="image" className="profile-pic" />
                      </div>
                      <div className="preview-item-content flex-grow">
                        <h6 className="preview-subject ellipsis font-weight-normal">Tim Cook</h6>
                        <p className="font-weight-light small-text text-muted mb-0">New product launch</p>
                      </div>
                    </a>
                    <a className="dropdown-item preview-item">
                      <div className="preview-thumbnail">
                        <img src="images/faces/face3.jpg" alt="image" class="profile-pic" />
                      </div>
                      <div className="preview-item-content flex-grow">
                        <h6 className="preview-subject ellipsis font-weight-normal"> Johnson</h6>
                        <p className="font-weight-light small-text text-muted mb-0">Upcoming board meeting</p>
                      </div>
                    </a>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a href="#" className="nav-link count-indicator ">
                    <i className="mdi mdi-message-reply-text"></i>
                  </a>
                </li>
                <li className="nav-item nav-search d-none d-lg-block ms-3">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="search">
                        <i className="mdi mdi-magnify"></i>
                      </span>
                    </div>
                    <input type="text" className="form-control" placeholder="search" aria-label="search" aria-describedby="search" />
                  </div>
                </li>
              </ul>
              <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
                <a className="navbar-brand brand-logo" href="/">
                  <img src={logo} alt="logo" />
                </a>
                <a className="navbar-brand brand-logo-mini" href="/">
                  <img src={logoMini} alt="logo" />
                </a>
              </div>
              <ul className="navbar-nav navbar-nav-right">
                {/* <li className="nav-item dropdown  d-lg-flex d-none">
                  <Link to="https://pay.doku.com/p-link/p/8IWPieI">
                    <button type="button" className="btn btn-primary btn-sm">
                      Payment Link
                    </button>
                  </Link>
                </li> */}
                <li className="nav-item dropdown d-lg-flex d-none">
                  <a className="dropdown-toggle show-dropdown-arrow btn btn-inverse-primary btn-sm" id="nreportDropdown" href="#" data-bs-toggle="dropdown">
                    Reports
                  </a>
                  <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="nreportDropdown">
                    <p className="mb-0 font-weight-medium float-left dropdown-header">Reports</p>
                    <a className="dropdown-item">
                      <i className="mdi mdi-file-pdf text-primary"></i>
                      Pdf
                    </a>
                    <a className="dropdown-item">
                      <i className="mdi mdi-file-excel text-primary"></i>
                      Excel
                    </a>
                  </div>
                </li>

                <li className="nav-item nav-profile dropdown">
                  <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" id="profileDropdown">
                    <span className="nav-profile-name">Wendy</span>
                    <span className="online-status"></span>
                    <img src={avatarProfile} alt="profile" className="rounded-2" />
                  </a>
                  <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
                    <a className="dropdown-item">
                      <i className="mdi mdi-settings text-primary"></i>
                      Settings
                    </a>
                    <a className="dropdown-item">
                      <i classNam="mdi mdi-logout text-primary"></i>
                      Logout
                    </a>
                  </div>
                </li>
              </ul>
              <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="horizontal-menu-toggle">
                <span className="mdi mdi-menu"></span>
              </button>
            </div>
          </div>
        </nav>

        <nav className="bottom-navbar">
          <div className="container">
            <ul className="nav  page-navigation">
              <li className="nav-item">
                <div className="nav-link">
                  <i className="mdi mdi-file-document-box menu-icon"></i>
                  <Link to="/" className="menu-title">
                    Dashboard
                  </Link>
                </div>
              </li>
              <li className="nav-item">
                <a href="" className="nav-link">
                  <i className="mdi mdi-finance menu-icon"></i>
                  <span className="menu-title">Transaction</span>
                  <i className="menu-arrow"></i>
                </a>
              </li>
              <li className="nav-item">
                <div className="nav-link">
                  <i className="mdi mdi-file-chart menu-icon"></i>
                  <Link to="/reports" className="menu-title">
                    Reports
                  </Link>
                  <i className="menu-arrow"></i>
                </div>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="mdi mdi-account menu-icon"></i>
                  <span className="menu-title">Settings</span>
                  <i className="menu-arrow"></i>
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      <div className="container-fluid page-body-wrapper">
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">
              <div className="col-sm-6 mb-4 mb-xl-0">
                <div className="d-lg-flex align-items-center">
                  <div>
                    <h3 className="text-dark font-bold mb-2">Hi, Welcome Customer</h3>
                    <h6 className="mb-2">Last login was 23 hours ago. View details</h6>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="d-flex align-items-center justify-content-md-end">
                  <div className="pe-1 mb-3 mb-xl-0">
                    <Link to="https://pay.doku.com/p-link/p/lkniijX">
                      <button type="button" className="btn btn-primary btn-sm">
                        Payment Link
                      </button>
                    </Link>
                  </div>
                  {/* <div className="pe-1 ml-1 mb-3 mb-xl-0">
                    <Link to="https://pay.doku.com/p-link/p/gHJo9wh">
                      <button type="button" className="btn btn-warning btn-sm">
                        Payment Link 4.5M
                      </button>
                    </Link>
                  </div> */}
                  <div className="pe-1 ml-1 mb-3 mb-xl-0">
                    <Link to="https://pay.doku.com/p-link/p/noXrzDh">
                      <button type="button" className="btn btn-info btn-sm">
                        Custom Payment
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-sm-8 flex-column d-flex stretch-card">
                <div className="row">
                  <div className="col-lg-4 d-flex grid-margin stretch-card">
                    <div className="card sale-diffrence-border">
                      <div className="card-body">
                        <h2 className="text-dark mb-2 font-weight-bold">Rp 0</h2>
                        <h4 class="card-title mb-2">Payment Success</h4>
                        <small class="text-muted">0 transactions</small>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 d-flex grid-margin stretch-card">
                    <div className="card sale-visit-statistics-border">
                      <div className="card-body">
                        <h2 className="text-dark mb-2 font-weight-bold">Rp 0</h2>
                        <h4 class="card-title mb-2">Payment Pending</h4>
                        <small class="text-muted">0 transactions</small>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 d-flex grid-margin stretch-card">
                    <div className="card sale-diffrence-border">
                      <div className="card-body">
                        <h2 className="text-dark mb-2 font-weight-bold">Rp 0</h2>
                        <h4 class="card-title mb-2">Payment Failed</h4>
                        <small class="text-muted">0 transactions</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 mb-4 mb-xl-0">
                    <div className="d-lg-flex align-items-center">
                      <div>
                        <h3 className="text-dark font-bold mb-2">Currency Converter</h3>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="card">
                      <div className="card-body">
                        <form className="forms-sample">
                          <div className="form-group">
                            <label>Amount</label>
                            {/* <CurrencyFormat className="form-control" thousandSeparator={true} prefix="₩" name="amount" value={currencyData.amount} onChange={handleInputChange} /> */}
                            {/* <CurrencyInput className="form-control form-form-control-lg" name="amount" placeholder="Enter Number" decimalsLimit={2} prefix="W" groupSeparator="." decimalSeparator="," value={currencyData.amount} onValueChange={handleInputChange} /> */}
                            <input className="form-control" type="number" name="amount" value={currencyData.amount} onChange={handleInputChange} />

                            <label className="mt-3">From</label>
                            <select className="form-control form-control-lg" name="fromCurrency" value={currencyData.fromCurrency} onChange={handleInputChange}>
                              {Object.keys(currencyData.exchangeRates).map((currency) => (
                                <option key={currency} value={currency}>
                                  {`${currency} - ${currencyNames[currency]}`}
                                </option>
                              ))}
                            </select>

                            <label className="mt-3">to</label>
                            <select className="form-control form-control-lg" name="toCurrency" value={currencyData.toCurrency} onChange={handleInputChange}>
                              {Object.keys(currencyData.exchangeRates).map((currency) => (
                                <option key={currency} value={currency}>
                                  {`${currency} - ${currencyNames[currency]}`}
                                </option>
                              ))}
                            </select>
                          </div>
                          <p>
                            {currencyData.amount} {currencyData.fromCurrency} ({currencyNames[currencyData.fromCurrency]}) is approximately {currencyData.convertedAmount} {currencyData.toCurrency} ({currencyNames[currencyData.toCurrency]})
                          </p>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div class="row">
                  <div class="col-sm-12 grid-margin d-flex stretch-card">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between">
                          <h4 class="card-title mb-2">Sales Difference</h4>
                          <div class="dropdown">
                            <a href="#" class="text-success btn btn-link  px-1">
                              <i class="mdi mdi-refresh"></i>
                            </a>
                            <a href="#" class="text-success btn btn-link px-1 dropdown-toggle dropdown-arrow-none" data-bs-toggle="dropdown" id="settingsDropdownsales">
                              <i class="mdi mdi-dots-horizontal"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="settingsDropdownsales">
                              <a class="dropdown-item">
                                <i class="mdi mdi-grease-pencil text-primary"></i>
                                Edit
                              </a>
                              <a class="dropdown-item">
                                <i class="mdi mdi-delete text-primary"></i>
                                Delete
                              </a>
                            </div>
                          </div>
                        </div>
                        <div>
                          <ul class="nav nav-tabs tab-no-active-fill" role="tablist">
                            <li class="nav-item">
                              <a class="nav-link active ps-2 pe-2" id="revenue-for-last-month-tab" data-bs-toggle="tab" href="#revenue-for-last-month" role="tab" aria-controls="revenue-for-last-month" aria-selected="true">
                                Revenue for last month
                              </a>
                            </li>
                            <li class="nav-item">
                              <a class="nav-link ps-2 pe-2" id="server-loading-tab" data-bs-toggle="tab" href="#server-loading" role="tab" aria-controls="server-loading" aria-selected="false">
                                Server loading
                              </a>
                            </li>
                            <li class="nav-item">
                              <a class="nav-link ps-2 pe-2" id="data-managed-tab" data-bs-toggle="tab" href="#data-managed" role="tab" aria-controls="data-managed" aria-selected="false">
                                Data managed
                              </a>
                            </li>
                            <li class="nav-item">
                              <a class="nav-link ps-2 pe-2" id="sales-by-traffic-tab" data-bs-toggle="tab" href="#sales-by-traffic" role="tab" aria-controls="sales-by-traffic" aria-selected="false">
                                Sales by traffic
                              </a>
                            </li>
                          </ul>
                          <div class="tab-content tab-no-active-fill-tab-content">
                            <div class="tab-pane fade show active" id="revenue-for-last-month" role="tabpanel" aria-labelledby="revenue-for-last-month-tab">
                              <div class="d-lg-flex justify-content-between">
                                <p class="mb-4">+5.2% vs last 7 days</p>
                                <div id="revenuechart-legend" class="revenuechart-legend">
                                  f
                                </div>
                              </div>
                              <canvas id="revenue-for-last-month-chart"></canvas>
                            </div>
                            <div class="tab-pane fade" id="server-loading" role="tabpanel" aria-labelledby="server-loading-tab">
                              <div class="d-flex justify-content-between">
                                <p class="mb-4">+5.2% vs last 7 days</p>
                                <div id="serveLoading-legend" class="revenuechart-legend">
                                  f
                                </div>
                              </div>
                              <canvas id="serveLoading"></canvas>
                            </div>
                            <div class="tab-pane fade" id="data-managed" role="tabpanel" aria-labelledby="data-managed-tab">
                              <div class="d-flex justify-content-between">
                                <p class="mb-4">+5.2% vs last 7 days</p>
                                <div id="dataManaged-legend" class="revenuechart-legend">
                                  f
                                </div>
                              </div>
                              <canvas id="dataManaged"></canvas>
                            </div>
                            <div class="tab-pane fade" id="sales-by-traffic" role="tabpanel" aria-labelledby="sales-by-traffic-tab">
                              <div class="d-flex justify-content-between">
                                <p class="mb-4">+5.2% vs last 7 days</p>
                                <div id="salesTrafic-legend" class="revenuechart-legend">
                                  f
                                </div>
                              </div>
                              <canvas id="salesTrafic"></canvas>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
              <div class="col-sm-4 flex-column d-flex stretch-card">
                <div class="row flex-grow">
                  <div class="col-sm-12 grid-margin stretch-card">
                    <div class="card">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-lg-8">
                            <h3 class="font-weight-bold text-dark">Jakarta,Indonesia</h3>
                            <p class="text-dark">
                              {date.toLocaleDateString("en-GB", {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                              })}
                            </p>
                            <div class="d-lg-flex align-items-baseline mb-3">
                              <h1 class="text-dark font-weight-bold">
                                23
                                <sup class="font-weight-light">
                                  <small>o</small>
                                  <small class="font-weight-medium">c</small>
                                </sup>
                              </h1>
                              <p class="text-muted ms-3">Partly cloudy</p>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="position-relative">
                              <img src={imgLive} class="w-100" alt="" />
                              <div class="live-info badge badge-success">Live</div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-12 mt-4 mt-lg-0">
                            <div class="bg-primary text-white px-4 py-4 card">
                              <div class="row">
                                <div class="col-sm-6 pl-lg-5">
                                  <h2>Rp 0</h2>
                                  <p class="mb-0">Payment Aggregator</p>
                                </div>
                                <div class="col-sm-6 climate-info-border mt-lg-0 mt-2">
                                  <h2>Rp 0</h2>
                                  <p class="mb-0">Fund On Hold</p>
                                </div>
                                <div class="col-sm-6 climate-info-border mt-lg-0 p-3">
                                  <h2>Rp 0</h2>
                                  <p class="mb-0">Ready to settle</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div class="row pt-3 mt-md-1">
                          <div class="col">
                            <div class="d-flex purchase-detail-legend align-items-center">
                              <div id="circleProgress1" class="p-2"></div>
                              <div>
                                <p class="font-weight-medium text-dark text-small">Sessions</p>
                                <h3 class="font-weight-bold text-dark  mb-0">26.80%</h3>
                              </div>
                            </div>
                          </div>
                          <div class="col">
                            <div class="d-flex purchase-detail-legend align-items-center">
                              <div id="circleProgress2" class="p-2"></div>
                              <div>
                                <p class="font-weight-medium text-dark text-small">Users</p>
                                <h3 class="font-weight-bold text-dark  mb-0">56.80%</h3>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 grid-margin stretch-card">
                    <div class="card">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-12">
                            <div class="d-flex align-items-center justify-content-between">
                              <h4 class="card-title mb-0">Total User Registered</h4>
                              <div class="dropdown">
                                <a href="#" class="text-success btn btn-link  px-1">
                                  <i class="mdi mdi-refresh"></i>
                                </a>
                                <a href="#" class="text-success btn btn-link px-1 dropdown-toggle dropdown-arrow-none" data-bs-toggle="dropdown" id="profileDropdownvisittoday">
                                  <i class="mdi mdi-dots-horizontal"></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdownvisittoday">
                                  <a class="dropdown-item">
                                    <i class="mdi mdi-grease-pencil text-primary"></i>
                                    Edit
                                  </a>
                                  <a class="dropdown-item">
                                    <i class="mdi mdi-delete text-primary"></i>
                                    Delete
                                  </a>
                                </div>
                              </div>
                            </div>
                            <p class="mt-1">Calculated in last 30 days</p>
                            <div class="d-lg-flex align-items-center justify-content-between">
                              <h1 class="font-weight-bold text-dark">4332</h1>
                              <div class="mb-3">
                                <button type="button" class="btn btn-outline-light text-dark font-weight-normal">
                                  Day
                                </button>
                                <button type="button" class="btn btn-outline-light text-dark font-weight-normal">
                                  Month
                                </button>
                              </div>
                            </div>
                            <canvas id="visitorsToday"></canvas>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-2 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body pb-0">
                    <div class="d-flex align-items-center justify-content-between">
                      <h2 class="text-success font-weight-bold">3</h2>
                      <i class="mdi mdi-account-outline mdi-18px text-dark"></i>
                    </div>
                  </div>
                  <canvas id="newClient"></canvas>
                  <div class="line-chart-row-title">TOTAL CLIENTS</div>
                </div>
              </div>
              <div class="col-lg-2 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body pb-0">
                    <div class="d-flex align-items-center justify-content-between">
                      <h2 class="text-danger font-weight-bold">3</h2>
                      <i class="mdi mdi-refresh mdi-18px text-dark"></i>
                    </div>
                  </div>
                  <canvas id="allProducts"></canvas>
                  <div class="line-chart-row-title">tOTAL Products</div>
                </div>
              </div>
              <div class="col-lg-2 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body pb-0">
                    <div class="d-flex align-items-center justify-content-between">
                      <h2 class="text-info font-weight-bold">0</h2>
                      <i class="mdi mdi-file-document-outline mdi-18px text-dark"></i>
                    </div>
                  </div>
                  <canvas id="invoices"></canvas>
                  <div class="line-chart-row-title">TOTAL TRANSACTIONS</div>
                </div>
              </div>
              <div class="col-lg-2 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body pb-0">
                    <div class="d-flex align-items-center justify-content-between">
                      <h2 class="text-warning font-weight-bold">0</h2>
                      <i class="mdi mdi-folder-outline mdi-18px text-dark"></i>
                    </div>
                  </div>
                  <canvas id="projects"></canvas>
                  <div class="line-chart-row-title">All PAYMENT LINK</div>
                </div>
              </div>
              <div class="col-lg-2 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body pb-0">
                    <div class="d-flex align-items-center justify-content-between">
                      <h2 class="text-secondary font-weight-bold">0</h2>
                      <i class="mdi mdi-cart-outline mdi-18px text-dark"></i>
                    </div>
                  </div>
                  <canvas id="orderRecieved"></canvas>
                  <div class="line-chart-row-title">pAYMENT Received</div>
                </div>
              </div>
              <div class="col-lg-2 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body pb-0">
                    <div class="d-flex align-items-center justify-content-between">
                      <h2 class="text-dark font-weight-bold">0</h2>
                      <i class="mdi mdi-cash text-dark mdi-18px"></i>
                    </div>
                  </div>
                  <canvas id="transactions"></canvas>
                  <div class="line-chart-row-title">PAYMENT Success</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer class="footer">
        <div class="footer-wrap">
          <div class="d-sm-flex justify-content-center justify-content-sm-between">
            <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">
              {new Date().getFullYear()} &copy;
              <a href="#" target="_blank" className="m-2">
                PT Sella Multi Indonesia{" "}
              </a>
            </span>
            <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">The system still development </span>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Dashboard;
