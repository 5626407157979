import logo from "./../assets/images/logo-full.png";
import logoMini from "./../assets/images/logo-site-mini.png";
import avatarProfile from "./../assets/images/faces/face28.png";

import { Link } from "react-router-dom";

const Reports = () => {
  return (
    <div className="container-scroller">
      <div className="horizontal-menu">
        <nav className="navbar top-navbar col-lg-col-12 col-12 p-0">
          <div className="container-fluid">
            <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
              <ul className="navbar-nav navbar-nav-left">
                <li className="nav-items ms-0 me-5 d-lg-flex d-none">
                  <a className="nav-link horizontal-nav-left-menu">
                    <i className="mdi-mdi-format-list-bulleted"></i>
                  </a>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link count-indicator dropdown-toggle d-flex align-items-center justify-content-center" id="notificationDropdown" href="#" data-bs-toggle="dropdown">
                    <i className="mdi mdi-bell mx-0"></i>
                    <span className="count bg-success">4</span>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="notificationDropdown">
                    <p className="mb-0 font-weight-normal float-left dropdown-header">Notifications</p>
                    <a className="dropdown-item preview-item">
                      <div className="preview-thumbnail">
                        <div className="preview-icon bg-success">
                          <i className="mdi mdi-information mx-0"></i>
                        </div>
                      </div>
                      <div className="preview-item-content">
                        <h6 className="preview-subject font-weight-normal">Application Error</h6>
                        <p className="font-weight-light small-text mb-0 text-muted">Just now</p>
                      </div>
                    </a>
                    <a className="dropdown-item preview-item">
                      <div className="preview-thumbnail">
                        <div className="preview-icon bg-warning">
                          <i className="mdi mdi-settings mx-0"></i>
                        </div>
                      </div>
                      <div className="preview-item-content">
                        <h6 className="preview-subject font-weight-normal">Settings</h6>
                        <p className="font-weight-light small-text mb-0 text-muted">Private message</p>
                      </div>
                    </a>
                    <a className="dropdown-item preview-item">
                      <div className="preview-thumbnail">
                        <div className="preview-icon bg-info">
                          <i className="mdi mdi-account-box mx-0"></i>
                        </div>
                      </div>
                      <div className="preview-item-content">
                        <h6 className="preview-subject font-weight-normal">New user registration</h6>
                        <p className="font-weight-light small-text mb-0 text-muted">2 days ago</p>
                      </div>
                    </a>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link count-indicator dropdown-toggle d-flex justify-content-center align-items-center" id="messageDropdown" href="#" data-bs-toggle="dropdown">
                    <i className="mdi mdi-email mx-0"></i>
                    <span className="count bg-primary">4</span>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="messageDropdown">
                    <p className="mb-0 font-weight-normal float-left dropdown-header">Messages</p>
                    <a className="dropdown-item preview-item">
                      <div className="preview-thumbnail">
                        <img src="images/faces/face4.jpg" alt="image" className="profile-pic" />
                      </div>
                      <div className="preview-item-content flex-grow">
                        <h6 className="preview-subject ellipsis font-weight-normal">David Grey</h6>
                        <p className="font-weight-light small-text text-muted mb-0">The meeting is cancelled</p>
                      </div>
                    </a>
                    <a className="dropdown-item preview-item">
                      <div className="preview-thumbnail">
                        <img src="images/faces/face2.jpg" alt="image" className="profile-pic" />
                      </div>
                      <div className="preview-item-content flex-grow">
                        <h6 className="preview-subject ellipsis font-weight-normal">Tim Cook</h6>
                        <p className="font-weight-light small-text text-muted mb-0">New product launch</p>
                      </div>
                    </a>
                    <a className="dropdown-item preview-item">
                      <div className="preview-thumbnail">
                        <img src="images/faces/face3.jpg" alt="image" class="profile-pic" />
                      </div>
                      <div className="preview-item-content flex-grow">
                        <h6 className="preview-subject ellipsis font-weight-normal"> Johnson</h6>
                        <p className="font-weight-light small-text text-muted mb-0">Upcoming board meeting</p>
                      </div>
                    </a>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a href="#" className="nav-link count-indicator ">
                    <i className="mdi mdi-message-reply-text"></i>
                  </a>
                </li>
                <li className="nav-item nav-search d-none d-lg-block ms-3">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="search">
                        <i className="mdi mdi-magnify"></i>
                      </span>
                    </div>
                    <input type="text" className="form-control" placeholder="search" aria-label="search" aria-describedby="search" />
                  </div>
                </li>
              </ul>
              <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
                <a className="navbar-brand brand-logo" href="#">
                  <img src={logo} alt="logo" />
                </a>
                <a className="navbar-brand brand-logo-mini" href="index.html">
                  <img src={logoMini} alt="logo" />
                </a>
              </div>
              <ul className="navbar-nav navbar-nav-right">
                <li className="nav-item dropdown  d-lg-flex d-none">
                  <button type="button" className="btn btn-inverse-primary btn-sm">
                    Payment Link
                  </button>
                </li>
                <li className="nav-item dropdown d-lg-flex d-none">
                  <a className="dropdown-toggle show-dropdown-arrow btn btn-inverse-primary btn-sm" id="nreportDropdown" href="#" data-bs-toggle="dropdown">
                    Reports
                  </a>
                  <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="nreportDropdown">
                    <p className="mb-0 font-weight-medium float-left dropdown-header">Reports</p>
                    <a className="dropdown-item">
                      <i className="mdi mdi-file-pdf text-primary"></i>
                      Pdf
                    </a>
                    <a className="dropdown-item">
                      <i className="mdi mdi-file-excel text-primary"></i>
                      Excel
                    </a>
                  </div>
                </li>

                <li className="nav-item nav-profile dropdown">
                  <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" id="profileDropdown">
                    <span className="nav-profile-name">Wendy</span>
                    <span className="online-status"></span>
                    <img src={avatarProfile} alt="profile" className="rounded-2" />
                  </a>
                  <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
                    <a className="dropdown-item">
                      <i className="mdi mdi-settings text-primary"></i>
                      Settings
                    </a>
                    <a className="dropdown-item">
                      <i classNam="mdi mdi-logout text-primary"></i>
                      Logout
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <nav className="bottom-navbar">
          <div className="container">
            <ul className="nav  page-navigation">
              <li className="nav-item">
                <div className="nav-link">
                  <i className="mdi mdi-file-document-box menu-icon"></i>
                  <Link to="/" className="menu-title">
                    Dashboard
                  </Link>
                </div>
              </li>
              <li className="nav-item">
                <a href="" className="nav-link">
                  <i className="mdi mdi-finance menu-icon"></i>
                  <span className="menu-title">Transaction</span>
                  <i className="menu-arrow"></i>
                </a>
              </li>
              <li className="nav-item">
                <div className="nav-link">
                  <i className="mdi mdi-file-chart menu-icon"></i>
                  <Link to="/reports" className="menu-title">
                    Reports
                  </Link>
                  <i className="menu-arrow"></i>
                </div>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="mdi mdi-account menu-icon"></i>
                  <span className="menu-title">Settings</span>
                  <i className="menu-arrow"></i>
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <div className="col-lg-12 py-4 px-3 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Report Data</h4>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>Full Name</th>
                    <th>Email</th>
                    <th>Invoice Number</th>
                    <th>Card Number</th>
                    <th>Approval Code</th>
                    <th>Payment Method</th>
                    <th>Bank Issuer</th>
                    <th>Amount</th>
                    <th>Date (GMT+7)</th>
                    <th>Status</th>
                  </tr>
                </thead>
                {/* <tbody>
                  <tr>
                    <td>Wendy</td>
                    <td>demo@demo.com</td>
                    <td>INV-PL-20231218141500521</td>
                    <td></td>
                    <td></td>
                    <td>Cards</td>
                    <td></td>
                    <td>100.000</td>
                    <td>12/18/2023 14:15:01</td>
                    <td>
                      <label class="badge badge-warning">Pending</label>
                    </td>
                  </tr>
                  <tr>
                    <td>Wendy</td>
                    <td>demo@demo.com</td>
                    <td>INV-PL-20231218140308905</td>
                    <td></td>
                    <td></td>
                    <td>Cards</td>
                    <td></td>
                    <td>9.999.500</td>
                    <td>12/15/2023 14:14:22</td>
                    <td>
                      <label class="badge badge-warning">Pending</label>
                    </td>
                  </tr>
                  <tr>
                    <td>leeyoungja</td>
                    <td>jaldavoid2772@gmail.com</td>

                    <td>INV-PL-20231215145810694</td>
                    <td>513792******1142</td>
                    <td>690330</td>
                    <td>Cards</td>
                    <td>LOTTE CARD CO., LTD.</td>
                    <td>100.000</td>
                    <td>12/15/2023 15:03:38</td>
                    <td>
                      <label class="badge badge-success">Success</label>
                    </td>
                  </tr>
                  <tr>
                    <td>leekamogseok</td>
                    <td>jjmejj112@never.com</td>
                    <td>INV-PL-20231215143023544</td>
                    <td>524141******1171</td>
                    <td></td>
                    <td>Cards</td>
                    <td>NONGHYUP BANK</td>
                    <td>1.000.000</td>
                    <td>12/15/2023 14:31:14</td>
                    <td>
                      <label class="badge badge-danger">Failed</label>
                    </td>
                  </tr>
                  <tr>
                    <td>joohyungdong</td>
                    <td>jjmejj112@never.com</td>
                    <td>INV-PL-20231215142400466</td>
                    <td>379183*****8006</td>
                    <td></td>
                    <td>Cards</td>
                    <td>SAMSUNG CARD CO., LTD.</td>
                    <td>1.000.000</td>
                    <td>12/15/2023 14:25:13</td>
                    <td>
                      <label class="badge badge-danger">Failed</label>
                    </td>
                  </tr>
                  <tr>
                    <td>leehojin</td>
                    <td>zheng7025@gmail.com</td>
                    <td>INV-PL-20231215142315276</td>
                    <td>546111******5187</td>
                    <td></td>
                    <td>Cards</td>
                    <td>NONGHYUP BANK</td>
                    <td>1.000.000</td>
                    <td>12/15/2023 14:23:45</td>
                    <td>
                      <label class="badge badge-danger">Failed</label>
                    </td>
                  </tr>
                  <tr>
                    <td>joohyungdong</td>
                    <td>air10025@naver.com</td>
                    <td>INV-PL-20231215142147369</td>
                    <td>546111******5187</td>
                    <td></td>
                    <td>Cards</td>
                    <td>SAMSUNG CARD CO., LTD.</td>
                    <td>1.000.000</td>
                    <td>12/15/2023 14:22:45</td>
                    <td>
                      <label class="badge badge-danger">Failed</label>
                    </td>
                  </tr>
                  <tr>
                    <td>juheongdong</td>
                    <td>air10025@naver.com</td>
                    <td>INV-PL-20231215141638884</td>
                    <td>379183*****8006</td>
                    <td></td>
                    <td>Cards</td>
                    <td>SAMSUNG CARD CO., LTD.</td>
                    <td>1.000.000</td>
                    <td>12/15/2023 14:18:45</td>
                    <td>
                      <label class="badge badge-danger">Failed</label>
                    </td>
                  </tr>
                  <tr>
                    <td>juheongdong</td>
                    <td>air10025@naver.com</td>
                    <td>INV-PL-20231215141505137</td>
                    <td>542416******1577</td>
                    <td></td>
                    <td>Cards</td>
                    <td>NONGHYUP BANK</td>
                    <td>1.000.000</td>
                    <td>12/15/2023 14:15:57</td>
                    <td>
                      <label class="badge badge-danger">Failed</label>
                    </td>
                  </tr>
                  <tr>
                    <td>juheongdong</td>
                    <td>air10025@naver.com</td>
                    <td>INV-PL-20231215141259280</td>
                    <td>379183*****8006</td>
                    <td></td>
                    <td>Cards</td>
                    <td>SAMSUNG CARD CO., LTD.</td>
                    <td>1.000.000</td>
                    <td>12/15/2023 14:14:22</td>
                    <td>
                      <label class="badge badge-danger">Failed</label>
                    </td>
                  </tr>
                  <tr>
                    <td>hangeumbok</td>
                    <td>air10025@naver.com</td>
                    <td>INV-PL-20231215114911283</td>
                    <td>557042******1014</td>
                    <td>331932</td>
                    <td>Cards</td>
                    <td>KB KOOKMIN CARD CO., LTD</td>
                    <td>1.000.000</td>
                    <td>12/15/2023 11:56:18</td>
                    <td>
                      <label class="badge badge-success">Success</label>
                    </td>
                  </tr>
                  <tr>
                    <td>JU HEONG DONG</td>
                    <td>air10025@naver.com</td>
                    <td>INV-PL-20231215114533973</td>
                    <td>542416******1577</td>
                    <td></td>
                    <td>Cards</td>
                    <td>NONGHYUP BANK</td>
                    <td>2.000.000</td>
                    <td>12/15/2023 11:46:13</td>
                    <td>
                      <label class="badge badge-danger">Failed</label>
                    </td>
                  </tr>
                  <tr>
                    <td>LEE KANG SEOK</td>
                    <td>air10025@naver.com</td>
                    <td>INV-PL-20231214174618211</td>
                    <td>524141******1171</td>
                    <td></td>
                    <td>Cards</td>
                    <td>NONGHYUP BANK</td>
                    <td>1.000.000</td>
                    <td>12/14/2023 17:52:35</td>
                    <td>
                      <label class="badge badge-danger">Failed</label>
                    </td>
                  </tr>
                  <tr>
                    <td>kimjunsub</td>
                    <td>kjs.void@gmail.com</td>
                    <td>INV-PL-20231214174449918</td>
                    <td>489016******1309</td>
                    <td>315605</td>
                    <td>Cards</td>
                    <td>HYUNDAI CARDS CO., LTD.</td>
                    <td>100.000</td>
                    <td>12/14/2023 17:46:06</td>
                    <td>
                      <label class="badge badge-success">Success</label>
                    </td>
                  </tr>
                  <tr>
                    <td>Juhyung dong</td>
                    <td>air10025@naver.com</td>
                    <td>INV-PL-20231214171047436</td>
                    <td></td>
                    <td></td>
                    <td>Cards</td>
                    <td></td>
                    <td>2.934.000</td>
                    <td>12/14/2023 17:10:50</td>
                    <td>
                      <label class="badge badge-warning">Pending</label>
                    </td>
                  </tr>
                </tbody> */}

                <tbody>
                  <tr className>
                    <td className="align-content-center full-width justify-center ">No data result</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;
