import React from "react";
import ReactDOM from "react-dom/client";
//import "./index.css";
// y
//import App from "./App";

import reportWebVitals from "./reportWebVitals";
import Dashboard from "./pages/Dashboard";

import "./assets/vendors/mdi/css/materialdesignicons.min.css";
import "./assets/vendors/base/vendor.bundle.base.css";
import "./assets/css/style.css";
import Routes from "./routes/Routes";
import App from "./App";

//import js
// import "./assets/js/template.js";
// import "./assets/js/dashboard.js";
// import "./assets/js/jquery.cookie.js"
// import "./assets/vendors/base/vendor.bundle.base.js";
// import "./assets/vendors/chart.js/Chart.min.js";
// import "./assets/vendors/progressbar.js/progressbar.min.js";
// import "./assets/vendors/chartjs-plugin-datalabels/chartjs-plugin-datalabels.js";
// import "./assets/vendors/justgage/justgage.js";
// import "./assets/vendors/justgage/raphael-2.1.4.min.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
