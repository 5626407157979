import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./assets/vendors/base/vendor.bundle.base.css";
import Dashboard from "./pages/Dashboard";
import Reports from "./pages/Reports";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<Dashboard />} />
          <Route path="reports" element={<Reports />} />
        </Route>
      </Routes>
    </BrowserRouter>
    
  );
};

export default App;
